import styled from 'styled-components';

import vars from 'variables';

import blueWhale from 'assets/HODLRanks/Rank/blue-whale.svg';
import crab from 'assets/HODLRanks/Rank/crab.svg';
import dolphin from 'assets/HODLRanks/Rank/dolphin.svg';
import humpbackWhale from 'assets/HODLRanks/Rank/humpback-whale.svg';
import killerWhale from 'assets/HODLRanks/Rank/killer-whale.svg';
import kraken from 'assets/HODLRanks/Rank/kraken.svg';
import megalodon from 'assets/HODLRanks/Rank/megalodon.svg';
import octopus from 'assets/HODLRanks/Rank/octopus.svg';
import shark from 'assets/HODLRanks/Rank/shark.svg';
import shrimp from 'assets/HODLRanks/Rank/shrimp.svg';
import swordfish from 'assets/HODLRanks/Rank/swordfish.svg';
import tunaFish from 'assets/HODLRanks/Rank/tuna-fish.svg';

import background from 'assets/CaseStudy/background.png';
import quote from 'assets/CaseStudy/quote.svg';

const Root = styled.div`
  .header {
    margin-top: -120px;
    position: relative;
    background: rgb(17, 20, 47) url(${background}) no-repeat bottom center;
  }

  .header img {
    left: 50%;
    position: absolute;
    object-fit: cover;
    border-radius: 300px;
  }

  .header h1 {
    line-height: 110%;
    text-align: center;
  }

  .header h1 div {
    margin: 0 auto;
  }

  .header .quote,
  .header .status {
    color: ${vars.colors.background};
    position: absolute;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
    border-radius: 50px;
  }

  .header .quote {
    font-weight: 600;
    text-align: center;
    background: white url(${quote}) no-repeat center 10px;
  }

  .header .quote span {
    display: block;
    color: #A9A9A9;
    letter-spacing: 1px;
  }

  .header .status {
    font-weight: 600;
    background: white no-repeat;
    background-size: 45px;
  }

  .header .status.blue-whale     { background-image: url(${blueWhale}); }
  .header .status.crab           { background-image: url(${crab}); }
  .header .status.dolphin        { background-image: url(${dolphin}); }
  .header .status.humpback-whale { background-image: url(${humpbackWhale}); }
  .header .status.killer-whale   { background-image: url(${killerWhale}); }
  .header .status.kraken         { background-image: url(${kraken}); }
  .header .status.megalodon      { background-image: url(${megalodon}); }
  .header .status.octopus        { background-image: url(${octopus}); }
  .header .status.shark          { background-image: url(${shark}); }
  .header .status.shrimp         { background-image: url(${shrimp}); }
  .header .status.swordfish      { background-image: url(${swordfish}); }
  .header .status.tunaFish       { background-image: url(${tunaFish}); }

  .header .status label {
    color: ${vars.colors.blue};
    display: block;
    font-weight: 700;
  }

  .content h2 {
    margin: 85px 0 25px;
    font-weight: 700;
    font-size: 1.6rem;
  }

  .content blockquote p {
    color: white !important;
    text-transform: uppercase;
  }

  .content blockquote small {
    color: ${vars.colors.blue};
    display: block;
    letter-spacing: 1px;
  }

  ${vars.desktop.mediaQuery} {
    .header {
      height: 306px;
      padding: 122px 0 530px;
      background-size: 1700px;
    }

    .header img {
      bottom: 50px;
      width: 460px;
      height: 460px;
      margin-left: -230px;
    }

    .header h1 {
      padding-top: 110px;
      font-size: 3.55rem;
      border-top: 1px solid rgb(74, 74, 104);
    }

    .header h1 div {
      width: 1300px;
    }

    .header .quote {
      bottom: 370px;
      left: 1270px;
      padding: 30px 30px 15px;
    }

    .header .quote span {
      font-size: 0.75rem;
    }

    .header .status {
      bottom: 120px;
      right: 1210px;
      padding: 25px 35px 25px 85px;
      background-position: 25px center;
    }

    .header .status label {
      font-size: 0.75rem;
    }

    .content blockquote p {
      margin: 80px 0 75px;
      padding-right: 300px;
      font-size: 1.7rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 155%;
    }

    .content blockquote small {
      font-size: 0.7rem;
    }

    .content p {
      color: #AAA;
      line-height: 165%;
    }

    .share-links {
      margin: 60px 0;
    }

    .join-the-communities {
      margin-top: 115px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .header {
      padding: 122px 0 435px;
      background-size: 700px;
    }

    .header .quote {
      bottom: 350px;
      right: 20px;
      padding: 15px 16px 8px;
      font-size: 0.475rem;
      background-size: 8px;
      background-position: top 6px center;
    }

    .header .quote span {
      font-size: 0.45rem;
    }

    .header .status {
      bottom: 240px;
      left: 18px;
      padding: 10px 15px 10px 42px;
      background-size: 24px;
      background-position: 10px center;
    }

    .header .status label {
      font-size: 0.45rem;
    }

    .header .status span {
      margin-top: -2px;
      font-size: 0.525rem;
      display: block;
    }

    .header img {
      bottom: 13px;
      width: 200px;
      height: 200px;
      margin-left: -102px;
    }

    .header h1 {
      padding-top: 50px;
      font-size: 1.25rem;
      line-height: 150%;
    }

    .content blockquote p {
      margin: 40px 0 37px;
      font-size: 0.85rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 200%;
    }

    .content blockquote small {
      margin-top: 6px;
      font-size: 0.6rem;
      font-weight: 700;
    }

    .join-the-communities {
      margin: 35px 0 50px;
    }
  }
`;

export { Root };
