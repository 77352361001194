import React, { Fragment } from "react";
import { Root } from "./style";

import { Helmet } from "react-helmet";

import reddit from "assets/Common/Socials/reddit.svg";
import telegram from "assets/Common/Socials/telegram.svg";
import twitter from "assets/Common/Socials/twitter.svg";

import vars from "variables";

const ShareLinks = ({ location, item }) => {
  const open = (url, text) => {
    const queryString = new URLSearchParams({
      text,
      url: location.href,
    }).toString();

    window.open(`${url}?${queryString}`, "_blank").focus();
  };

  const title = (item.name || item.title).replace(/(<([^>]+)>)/g, "");
  const image = `${vars.assetsPrefix}/${item.image}`;
  const text = `Check this out! ${JSON.stringify(title)}`;

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={item.intro} />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={image} />
      </Helmet>
      <Root className="share-links">
        <span>Share:</span>
        <a
          onClick={() =>
            open(
              "https://twitter.com/intent/tweet",
              `${text} via @HODL_OFFICIAL`
            )
          }
        >
          <img src={twitter} />
        </a>
        <a
          onClick={() =>
            open("https://t.me/share/url", `${text} via @hodlinvestorgroup`)
          }
        >
          <img src={telegram} />
        </a>
        <a
          onClick={() =>
            open("https://www.reddit.com/submit", `${text} via u/HodlToken`)
          }
        >
          <img src={reddit} />
        </a>
      </Root>
    </Fragment>
  );
};

export default ShareLinks;
