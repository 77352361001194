import styled from "styled-components";

import vars from "variables";

const Root = styled.div`
  display: flex;
  align-items: baseline;
  line-height: 100%;

  span {
    color: #aaa;
    align-self: center;
    text-transform: uppercase;
  }

  a {
    margin-right: 12px;
    cursor: pointer;
  }

  ${vars.desktop.mediaQuery} {
    & {
      margin-top: 30px;
      margin-bottom: -10px;
    }

    span {
      margin-right: 20px;
      font-size: 1.025rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      margin: 20px 0 50px;
    }

    span {
      margin-right: 12px;
    }
  }
`;

export { Root };
