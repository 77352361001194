import React from "react";
import { Root } from "./style";

import kebabCase from "lodash/kebabCase";

import JoinTheCommunities from "components/Common/JoinTheCommunities";
import RawHTML from "components/Common/RawHTML";
import ShareLinks from "components/Common/ShareLinks";
import SiteImage from "components/Common/SiteImage";

const CaseStudy = ({ location, pageContext: { caseStudy } }) => {
  return (
    <Root>
      <div className="header">
        <h1>
          <RawHTML html={caseStudy.title} />
        </h1>
        <SiteImage image={caseStudy.image} />
        {caseStudy.quote ? (
          <div className="quote">
            {caseStudy.quote.replace(/---.*/, "")}
            <span>{caseStudy.quote.replace(/.*---/, "")}</span>
          </div>
        ) : (
          ""
        )}
        {caseStudy.hodlStatus ? (
          <div className={`status ${kebabCase(caseStudy.hodlStatus)}`}>
            <label>HODL status</label>
            <span>{caseStudy.hodlStatus}</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="body-width">
        <ShareLinks location={location} item={caseStudy} />
        <RawHTML
          className="content"
          html={caseStudy.content.replace(
            /--- (.*?)<\//,
            (_, m) => `<small>${m}</small></`
          )}
        />
        <JoinTheCommunities />
      </div>
    </Root>
  );
};

export default CaseStudy;
